import React, { Suspense } from 'react';
import { Tab } from 'react-bootstrap';

import CodeMirrorEditor from './CodeMirrorEditor';
import ContentViewer from './code-editor/ContentViewer';
import P5SketchIFrame from './code-editor/P5SketchIFrame';
import GradeFile from './code-editor/GradeFile';
import { CodeData, Gradebook, LessonInfo } from '../types/types';
import { logger } from './Logger';
import GradebookFile from './code-editor/GradebookFile';


const QuillEditor = React.lazy(() => import('./QuillEditor'));

interface TabContentProps {
  codeDataRef: React.MutableRefObject<CodeData>;
  tabs: string[];
  isTeacher: boolean;
  paneType: 'codePane' | 'outputPane';
  lastTypingTimestamps: { [key: string]: { [key: string]: number } };
  name: string;
  lesson: string;
  permissionedFiles: string[];
  copyEnabled: boolean;
  gradebook: Gradebook;
  lessonsInfo: LessonInfo[];
  onAddGradeTab: (studentName: string, lessonName: string) => void;
  onUpdateGradeMax: (lessonName: string, gradeMax: number | null) => void;
  onUpdateGradeAndFeedback: (studentName: string, lessonId: string, grade: number | null, feedback: string) => void;
  onUpdateFiles: (fileName: string, text: string, fileType: 'student' | 'teacher', isCodeFile: boolean, caretPosition?: { line: number; col: number }, highlightedRange?: { anchor: { line: number; col: number }; head: { line: number; col: number } }, studentName?: string) => void;
  getNamesTyping: (fileName: string) => string[];
  highlightOption: string;
  isRunningP5: boolean;
  p5SketchCode: string;
  runKey: number;
  output: string;
  isFullScreenSlides: boolean;
  isFullScreenQuiz: boolean;
  toggleFullScreenSlides: () => void;
  toggleFullScreenQuiz: () => void;
  handleContentUrlChange: (fileName: string, newUrl: string) => void;
}


// Why are there unused props check what is happening here
const TabContent: React.FC<TabContentProps> = ({
  codeDataRef,
  tabs,
  isTeacher,
  paneType,
  lastTypingTimestamps,
  name,
  lesson,
  permissionedFiles,
  copyEnabled,
  gradebook,
  lessonsInfo,
  onAddGradeTab,
  onUpdateGradeMax,
  onUpdateGradeAndFeedback,
  onUpdateFiles,
  getNamesTyping,
  highlightOption,
  isRunningP5,
  p5SketchCode,
  runKey,
  output,
  isFullScreenSlides,
  isFullScreenQuiz,
  toggleFullScreenSlides,
  toggleFullScreenQuiz,
  handleContentUrlChange,
}) => {

  const lessonNameToId: { [key: string]: string } = {};

  lessonsInfo.forEach((lessonInfo: LessonInfo) => {
    lessonNameToId[lessonInfo.name] = lessonInfo.id;
  });

  return (
    <div className={`tab-content ${paneType === 'outputPane' ? 'output' : 'main'}`}>
      {tabs.map((tabName) => {
        const [fileType, ...rest] = tabName.split('-');
        const fileName = rest.pop() ?? '';
        const fileNameExcExt = fileName.split('.')[0]
        const studentName = rest.join('-');
        const fileExtension = fileName.split('.').pop()?.toLowerCase();
        // need to pull out the lesson id from 

        return (
          <Tab.Pane key={tabName} eventKey={tabName}>
            {fileExtension === 'info' ? (
              <div className="quill-container">
                <Suspense fallback={<div>Loading editor...</div>}>
                  <QuillEditor
                    value={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                    onChange={(text: string) => {
                      if (isTeacher) {
                        onUpdateFiles(fileName, text, 'teacher', false);
                      }
                    }}
                    readOnly={!isTeacher}
                  />
                </Suspense>
              </div>
            ) : fileExtension === 'slides' ? (
              <ContentViewer 
                contentType='slides'
                url={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                isFullScreen={isFullScreenSlides}
                onFullScreenToggle={toggleFullScreenSlides}
                isTeacher={isTeacher}
                onUrlChange={(newUrl: any) => handleContentUrlChange(fileName, newUrl)}
              />
            ) : fileExtension === 'quiz' ? (
              <ContentViewer
                contentType='quiz'
                url={codeDataRef.current.teacherFiles?.[fileName]?.text || ''}
                isFullScreen={isFullScreenQuiz}
                onFullScreenToggle={toggleFullScreenQuiz}
                isTeacher={isTeacher}
                onUrlChange={(newUrl: any) => handleContentUrlChange(fileName, newUrl)}
              />
            ) : fileExtension === 'grade' ? (
              <GradeFile
                name={studentName}
                lessonName={fileNameExcExt}
                grade={gradebook?.[studentName]?.[lessonNameToId[fileNameExcExt]]?.grade ?? null}
                gradeMax={lessonsInfo.filter((lessonInfo) => lessonInfo.name === fileNameExcExt)[0].gradeMax?? 0}
                feedback={gradebook?.[studentName]?.[lessonNameToId[fileNameExcExt]]?.feedback ?? null}
                isTeacher={isTeacher}
                onUpdateGradeAndFeedback={(grade: number | null, feedback: string) => {onUpdateGradeAndFeedback(studentName, lessonNameToId[fileNameExcExt], grade, feedback);}}
              />
            ) : fileExtension === 'gb' ? (
              <GradebookFile
                lessonsInfo={lessonsInfo}
                gradebook={gradebook}
                isTeacher={isTeacher}
                onAddGradeTab={onAddGradeTab}
                onUpdateGradeMax={onUpdateGradeMax}
              />
            ) : fileExtension === 'txt' ? (
              <CodeMirrorEditor
                value={
                  fileType === 'teacher'
                    ? codeDataRef.current.teacherFiles?.[fileName]?.text || ''
                    : codeDataRef.current.studentFiles?.[studentName || name]?.[fileName]?.text || ''
                }
                onUpdate={(text, caretPosition, highlightedRange) => 
                  onUpdateFiles(fileName, text, fileType as 'student' | 'teacher', true, caretPosition, highlightedRange, studentName || name)}
                readOnly={fileType === 'teacher' && !isTeacher && !permissionedFiles.includes(fileName)}
                userInfo={codeDataRef.current.userInfo}
                typingNames={getNamesTyping(fileName)}
                currentUser={name}
                fileOwner={fileType === 'teacher' ? 'teacher-name' : `student-${studentName || name}`}
                isStudentFile={fileType !== 'teacher'}
                fileName={fileName}
                fileExtension={fileExtension ?? 'py'}
                highlightOption={'plain'}
                isOutputPane={paneType === 'outputPane'}
                copyEnabled={isTeacher || copyEnabled}
              />
            ) : (
              <CodeMirrorEditor
                value={
                  fileType === 'teacher'
                    ? codeDataRef.current.teacherFiles?.[fileName]?.text || ''
                    : codeDataRef.current.studentFiles?.[studentName || name]?.[fileName]?.text || ''
                }
                onUpdate={(text, caretPosition, highlightedRange) => 
                  onUpdateFiles(fileName, text, fileType as 'student' | 'teacher', true, caretPosition, highlightedRange, studentName || name)}
                readOnly={fileType === 'teacher' && !isTeacher && !permissionedFiles.includes(fileName)}
                userInfo={codeDataRef.current.userInfo}
                typingNames={getNamesTyping(fileName)}
                currentUser={name}
                fileOwner={fileType === 'teacher' ? 'teacher-name' : `student-${studentName || name}`}
                isStudentFile={fileType !== 'teacher'}
                fileName={fileName}
                fileExtension={fileExtension ?? 'py'}
                highlightOption={highlightOption}
                isOutputPane={paneType === 'outputPane'}
                copyEnabled={isTeacher || copyEnabled}
              />
            )}
          </Tab.Pane>
        );
      })}
      {paneType === 'outputPane' && (
        <Tab.Pane eventKey="console">
          {isRunningP5 && (<P5SketchIFrame code={p5SketchCode} runKey={runKey} />)}
          <div className="visual-output-container">
            <div id="turtle-canvas-pane" className="turtle-canvas"></div>
            <div id="pygame-sk-container-pane">
              <div id="pygame-sk-canvas-pane"></div>
            </div>
            <div className="output-text-container custom-scrollbar">
              <pre className="output" id="output-direct">{output}</pre>
            </div>
            <div className="input-container-container"></div>
          </div>
        </Tab.Pane>
      )}
    </div>
  );
};

export default TabContent;