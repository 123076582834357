import chroma from 'chroma-js';

import { LessonInfo } from '../types/types';


export const getInitialsOld = (name: string) => {
  const names = name.split(' ');
  const initials = names.map((name) => name.charAt(0)).join('');
  return initials.toUpperCase();
};

export const getInitials = (name: string) => {
  const names = name.trim().split(' ');

  if (names.length === 1) {
    if (names[0].length === 1) {
      return names[0].toUpperCase();
    } else {
      return names[0].charAt(0).toUpperCase() + names[0].charAt(1).toLowerCase();
    }
  } else {
    const initials = names.map((name) => name.charAt(0).toUpperCase());
    return initials.slice(0, 2).join('');
  }
};

export const getLessonString = (lessonsInfo: LessonInfo[], lesson: string) => {
  const lessonNumber = lessonsInfo.find((lessonInfo) => lessonInfo.name === lesson)?.index?? 1;
  return `Lesson ${lessonNumber}: ${lesson}`;
};

export const isTabCode = (tabName: string | null) => {
  if (!tabName) { return false; }
  const extension = tabName.split('.').pop() || '';
  return extension === 'py' || extension === 'js';
}

export const getFileNameFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts.length >= 3 ? parts[2] : parts[0];
};

export const getFileTypeFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts[0];
};

export const getNameFromTabName = (tabName: string | null) => {
  if (!tabName) { return 'error'; }
  const parts = tabName.split('-');
  return parts.length >= 3 ? parts[1] : parts[0];
};

// export function generateShades(background: string, foreground: string, studentBackground: string, count: number = 10): { shades: string[], purpleShades: string[] } {
//   // Generate background to foreground shades
//   const scale = chroma.scale([background, foreground]).mode('lab');
//   const shades = Array.from({ length: count }, (_, i) => scale(i / (count - 1)).hex());
//   // Generate purple to foreground shades
//   const purpleScale = chroma.scale([studentBackground, foreground]).mode('lab');
//   const purpleShades = Array.from({ length: count }, (_, i) => purpleScale(i / (count - 1)).hex());
//   return { shades, purpleShades };
// }

function hexToRgb(hex: string | undefined): [number, number, number] {
  if (!hex) {
    console.warn('Undefined color provided to hexToRgb, hex:', hex);
    return [0, 0, 0]; // Return black as a fallback
  }

  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '');

  // If it's a 3 character hex code, convert it to 6 characters
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  // Make sure we have a valid hex code
  if (hex.length !== 6) {
    console.warn('Invalid HEX color:', hex);
    return [0, 0, 0]; // Return black as a fallback
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return [r, g, b];
}

function rgbToHex(r: number, g: number, b: number): string {
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

function interpolateColor(color1: string, color2: string, factor: number): string {
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);
  const result = rgb1.map((c1, i) => {
    const c2 = rgb2[i];
    return Math.round(c1 + factor * (c2 - c1));
  });
  return rgbToHex(result[0], result[1], result[2]);
}

export function generateShades(background: string, foreground: string, purpleMain: string): { shades: string[], purpleShades: string[] } {
  const shades = [];
  const purpleShades = [];

  // Generate 10 background to foreground shades
  for (let i = 0; i < 10; i++) {
    const factor = i / 9;
    shades.push(interpolateColor(background, foreground, factor));
  }

  // Generate 6 purple shades from background to main purple
  for (let i = 0; i < 6; i++) {
    const factor = i / 5;
    purpleShades.push(interpolateColor(background, purpleMain, factor));
  }

  return { shades, purpleShades };
}