import React, { useState } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { MdCheckCircleOutline, MdOutlineCancel } from 'react-icons/md';

import './GradeBox.css'
import { logger } from '../Logger';

interface GradeBoxProps {
  submitted: boolean;
  grade: number | null;
  gradeMax: number | null;
  isTeacher: boolean;
  isGradeMax?: boolean;
  isGradebook?: boolean;
  onAddGradeTab?: () => void;
  onUpdateGradeMax?: (newGradeMax: number | null) => void;
}

const GradeBox: React.FC<GradeBoxProps> = ({ 
  submitted, 
  grade, 
  gradeMax, 
  isTeacher,
  isGradeMax = false,
  isGradebook = false,
  onAddGradeTab, 
  onUpdateGradeMax,
}) => {
  const [showModal, setShowModal] = useState(false);

  let boxColor: string;
  let content: React.ReactNode;
  let tooltipContent: string;
  let isClickable: boolean = false;


  if (isGradebook) {
    if (isGradeMax) {
      boxColor = 'grey';
      content = gradeMax === null ? '-' : `${gradeMax}`;
      tooltipContent = isTeacher ? 'Click to change the maximum grade' : 'Maximum grade for the lesson';
      isClickable = isTeacher;
    } else {
      if (gradeMax === null) {
        content = '';
        tooltipContent = 'Lesson not graded';
        isClickable = false;
      } else if (grade === null) {
        content = '-';
        tooltipContent = isTeacher ? 'Click to grade' : 'Project submitted waiting for grading';
        isClickable = isTeacher;
      } else {
        content = grade.toString();
        tooltipContent = isTeacher ? 'Click to grade' : 'Click to see feedback';
        isClickable = true;
      }
      if (!submitted) {
        boxColor = 'grey';
      } else if (grade === null && gradeMax !== null) {
        boxColor = 'yellow';
      } else {
        boxColor = 'green';
      }
    }
    
  } else if (isGradeMax) {
    boxColor = 'grey';
    content = gradeMax === null ? 'n/a' : `?/${gradeMax}`;
    tooltipContent = isTeacher ? 'Click to change the maximum grade' : 'Maximum grade for the lesson';
    isClickable = isTeacher;

  } else if (gradeMax === null) {
    if (submitted) {
      boxColor = 'green';
      content = <MdCheckCircleOutline />;
      tooltipContent = 'Lesson completed';
    } else {
      boxColor = 'grey';
      content = <MdOutlineCancel />;
      tooltipContent = 'Lesson not completed';
    }
    isClickable = false;

  } else {
    if (!submitted) {
      boxColor = 'grey';
      content = `?/${gradeMax}`;
      tooltipContent = `Project not submitted max grade ${gradeMax}`;
      isClickable = true;
    } else if (grade === null) {
      boxColor = 'yellow';
      content = `?/${gradeMax}`;
      tooltipContent = 'Project submitted waiting for grading';
      isClickable = true;
    } else {
      boxColor = 'green';
      content = `${grade}/${gradeMax}`;
      tooltipContent = 'Project submitted and graded';
      isClickable = true;
    }
  }

  const renderTooltip = (props: any) => (
    <Tooltip id="grade-box-tooltip" {...props}>
      {tooltipContent}
    </Tooltip>
  );

  const handleClick = (e: React.MouseEvent) => {
    if (!isClickable) return;
    e.stopPropagation(); 

    if (isGradeMax) {
      setShowModal(true);
    } else if (isTeacher || (submitted && grade)) {
      if (onAddGradeTab) {
        onAddGradeTab();
      }
    }
  };

  const handleClose = () => setShowModal(false);

  const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (onUpdateGradeMax) {
      const formData = new FormData(event.currentTarget);
      const newGradeMax = formData.get('gradeMax') as string;
      const parsedGradeMax = newGradeMax === '' ? null : Number(newGradeMax);
      if (parsedGradeMax === null || !isNaN(parsedGradeMax)) {
        onUpdateGradeMax(parsedGradeMax);
        handleClose();
      } else {
        alert('Please enter a valid number');
      }
    }
  };

  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className={`grade-box ${boxColor} ${isClickable ? 'clickable' : ''}`} onClick={handleClick}>
          {content}
        </div>
      </OverlayTrigger>

      <Modal show={showModal} onHide={handleClose}>
        <Form onSubmit={handleSave}>
          <Modal.Header closeButton>
            <Modal.Title>Update Maximum Grade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>New Maximum Grade (leave blank for no maximum):</Form.Label>
              <Form.Control
                type="number"
                name="gradeMax"
                defaultValue={gradeMax?.toString() ?? ''}
                min="0"
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default GradeBox;