
import { BrowserRouter, Route, Routes } from "react-router-dom"
import React, { Component, ErrorInfo } from 'react';

import { ColorSchemeProvider } from "./components/ColorSchemeContext";
import { AppProvider } from './AppContext';
  
import StudentScreen from "./components/StudentScreen";
import TeacherScreen from "./components/TeacherScreen";
import TestEditor from "./components/TestEditor";
import CourseSelectionScreen from "./components/CourseSelectionScreen";
import LandingPage from "./components/LandingPage";

import { sendTelegramError } from './services/telegramService';

import './App.css';


interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}


class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
      super(props);
      this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): State {
      return { hasError: true };
  }

  componentDidMount() {
      // Catch unhandled promise rejections
      window.addEventListener('unhandledrejection', this.handlePromiseRejection);
      
      // Override console.error
      const originalConsoleError = console.error;
      console.error = (...args) => {
          // Send to Telegram
          sendTelegramError(args.join(' '));
          // Call original console.error
          originalConsoleError.apply(console, args);
      };
      
      // Catch runtime errors
      window.onerror = (message, source, lineno, colno, error) => {
          sendTelegramError(`Runtime Error: ${message}\nSource: ${source}\nLine: ${lineno}\nColumn: ${colno}\nError: ${error}`);
          return false;
      };
  }

  componentWillUnmount() {
      window.removeEventListener('unhandledrejection', this.handlePromiseRejection);
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      sendTelegramError(`React Error: ${error}\nStack: ${errorInfo.componentStack}`);
  }

  handlePromiseRejection = (event: PromiseRejectionEvent) => {
      sendTelegramError(`Unhandled Promise Rejection: ${event.reason}`);
  };

  render() {
      if (this.state.hasError) {
          return <h1>Something went wrong.</h1>;
      }

      return this.props.children;
  }
}

function App() {
  return (
    <ErrorBoundary>
      <AppProvider>
        <ColorSchemeProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/landing-page" element={<LandingPage />} />
              <Route path="/student" element={<CourseSelectionScreen isTeacher={false} />} />
              <Route path="/student/:course" element={<StudentScreen />} />
              <Route path="/teacher406816" element={<CourseSelectionScreen isTeacher={true} />} />
              <Route path="/teacher406816/:course" element={<TeacherScreen />} />
              <Route path="/test-editor" element={<TestEditor />} />
            </Routes>
          </BrowserRouter>
        </ColorSchemeProvider>
      </AppProvider>
    </ErrorBoundary>
  );
}

export default App;
