import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import { Button } from 'react-bootstrap';

import { logger } from "./Logger";

import './LandingPage.css';

const animationClasses = [
  'fade-hidden-up',
  'fade-hidden-left',
  'fade-hidden-right',
  'fade-hidden-zoom',
  'fade-hidden-rotate',
  'fade-hidden-blur',
  'fade-hidden-flip',
  'fade-hidden-bounce',
];

const verbs = [
  { text: 'Multiplayer', color: '#6b3aa8' },     // lighter purple
  { text: 'AI Powered', color: '#2f3399' },      // lighter blue
  { text: 'All-in-One', color: '#ffffff' },      // white
  { text: 'Fun', color: '#30af35' },             // green
  { text: 'Inclusive', color: '#c91979' },       // pink
  { text: 'Easy to Use', color: '#933a2a' },     // orange
];

const LandingPage: React.FC = () => {
  logger.log('Creating LandingPage');
  const [passcode, setPasscode] = useState('');
  const [currentVerbIndex, setCurrentVerbIndex] = useState(0);
  const [isVerbChanging, setIsVerbChanging] = useState(false);
  const [email, setEmail] = useState('');
  const [submitStatus, setSubmitStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const navigate = useNavigate();

   // Cycle through verbs
  useEffect(() => {
    const interval = setInterval(() => {
      setIsVerbChanging(true);
      
      setTimeout(() => {
        setCurrentVerbIndex((prev) => (prev + 1) % verbs.length);
        setIsVerbChanging(false);
      }, 500); // Half of transition duration
    }, 2000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, []);

  // https://docs.google.com/forms/d/e/1FAIpQLScKUiMl-silwjxjLHQgZSmO2qsoP41vy3QfCKThvfU_iA7-Ug/viewform?usp=sf_link

  // AKfycbx-1HGlPlakahp4ZbeYK9ckqpiu7PBWSzPP9FYmcz4f84mYSyUhlExlE115thguskkv

  // https://script.google.com/macros/s/AKfycbx-1HGlPlakahp4ZbeYK9ckqpiu7PBWSzPP9FYmcz4f84mYSyUhlExlE115thguskkv/exec

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitStatus('loading');

    try {
      // Replace with your deployed Apps Script URL
      const SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbx-1HGlPlakahp4ZbeYK9ckqpiu7PBWSzPP9FYmcz4f84mYSyUhlExlE115thguskkv/exec';
      
      const response = await fetch(`${SCRIPT_URL}?email=${encodeURIComponent(email)}`, {
        method: 'GET',
        mode: 'no-cors', // Add this line
      });

      // Since we're using no-cors, we won't get a proper response
      // So we'll assume success if no error is thrown
      setSubmitStatus('success');
      setEmail('');
      setTimeout(() => setSubmitStatus('idle'), 3000);
    } catch (error) {
      setSubmitStatus('error');
      setTimeout(() => setSubmitStatus('idle'), 3000);
    }
  };

  // Get random animation class
  const getRandomAnimation = () => {
    const randomIndex = Math.floor(Math.random() * animationClasses.length);
    return animationClasses[randomIndex];
  };

  // Add new intersection observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.3 } // Trigger when 10% of the element is visible
    );

    // Observe all pages
    document.querySelectorAll('.page').forEach((page) => {
      observer.observe(page);
    });

    return () => observer.disconnect();
  }, []);

  const goToEditor = () => {
    navigate('/student'); 
  };

  const goToEditorTeacher = () => {
    const code = prompt("Please enter the teacher code:");
    if (code === '123456') {  // !!!! passcode taken out temporarily, put this back later
      navigate('/teacher123456');
    } else if (code !== null) { // Check if user didn't click "Cancel"
      alert("Incorrect code. Access denied.");
    }
  };

  const handlePasscodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passcode === '153') {
      navigate('/student/a_level_practice_paper_cp');
    } else if (passcode === '268') {
      navigate('/student/a_level_practice_paper_lt');
    } else {
      alert("Incorrect code. Access denied.");
    }
    setPasscode('');
  };

  // action="https://docs.google.com/forms/d/e/1FAIpQLScKUiMl-silwjxjLHQgZSmO2qsoP41vy3QfCKThvfU_iA7-Ug/formResponse"

  // https://docs.google.com/forms/d/e/1FAIpQLScKUiMl-silwjxjLHQgZSmO2qsoP41vy3QfCKThvfU_iA7-Ug/viewform?usp=pp_url&entry.1502891958=test@gmail.com

  
  return (
    <div className="landing-page">
      <div className="page">
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-01.png`} alt="Kita Landing Page 1" />
        <div className="signup-overlay">
          <div className="signup-container">
            <h2>Join Our Free Trial Program</h2>
            <p>We are looking for teachers, schools, tutors and students to participate in our trials.</p>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                fetch('https://docs.google.com/forms/d/e/1FAIpQLScKUiMl-silwjxjLHQgZSmO2qsoP41vy3QfCKThvfU_iA7-Ug/formResponse', {
                  method: 'POST',
                  mode: 'no-cors',
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                  body: new URLSearchParams({
                    'entry.1502891958': email, // Replace with your entry ID
                  }),
                }).then(() => {
                  setSubmitStatus('success');
                  setEmail('');
                  setTimeout(() => setSubmitStatus('idle'), 3000);
                });
              }}
            >
              <input
                type="email"
                name="emailAddress"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
              />
              <Button type="submit">Join Trial</Button>
              {submitStatus === 'success' && (
                <p className="success-message">Thanks for joining! We'll be in touch soon.</p>
              )}
            </form>
            <iframe name="hidden_iframe" style={{ display: 'none' }}></iframe>
          </div>
        </div>
        <div className="title-overlay">
          <h1 className="main-title">
            <span className="small-text">The</span>
            <div className="verb-container">
              <span 
                className={`changing-verb ${isVerbChanging ? 'fade-out' : ''}`}
                style={{ color: verbs[currentVerbIndex].color }}
              >
                {verbs[currentVerbIndex].text}
              </span>
            </div>
            <span className="code-editor">Code Editor</span>
            <span className="small-text">for the Classroom</span>
          </h1>
        </div>
        <div className="passcode-container go-to-editor-btn bottom-right">
          <form onSubmit={handlePasscodeSubmit}>
            <label htmlFor="passcode">Enter classroom code (3 digits):</label>
            <input
              type="text"
              pattern="[0-9]*"
              inputMode="numeric"
              id="passcode"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setPasscode(e.target.value);
                }
              }}
              value={passcode}
              placeholder="Enter code"
            />
            <Button type="submit" variant="light" className='submit-margin-left-1rem'>Submit</Button>
          </form>
        </div>
        {false && (
          <>
            <button onClick={goToEditor} className="go-to-editor-btn student">
              Go to the Student Code Editor
            </button>
            <button onClick={goToEditorTeacher} className="go-to-editor-btn teacher">
              Go to the Teacher Code Editor
            </button>
          </>
        )}
      </div>
      <div className={`page fade-hidden-left`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-02.png`} alt="Kita Landing Page 2" />
      </div>
      <div className={`page fade-hidden-zoom`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-03.png`} alt="Kita Landing Page 3" />
      </div>
      <div className={`page fade-hidden-left`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-04.png`} alt="Kita Landing Page 4" />
      </div>
      <div className={`page fade-hidden-rotate`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-05.png`} alt="Kita Landing Page 5" />
      </div>
      <div className={`page fade-hidden-blur`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-06.png`} alt="Kita Landing Page 6" />
      </div>
      <div className={`page fade-hidden-flip`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-07.png`} alt="Kita Landing Page 7" />
      </div>
      <div className={`page fade-hidden-zoom`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-08.png`} alt="Kita Landing Page 8" />
      </div>
      <div className={`page fade-hidden-up`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-09.png`} alt="Kita Landing Page 9" />
      </div>
      <div className={`page fade-hidden-right`}>
        <img src={`${process.env.PUBLIC_URL}/Images/Kita 2024 - pilot - DRAFT-10.png`} alt="Kita Landing Page 10" />
      </div>
    </div>
  );
};

export default LandingPage;