import React, { useState, useEffect, useRef, useCallback, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import debounce from 'lodash/debounce';

import EditorHeader from './EditorHeader';
import CodeEditor from './CodeEditor';
import { useColorScheme } from './ColorSchemeContext';
import { CodeData, initialCodeData, defaultStealthModeUserInfo, LessonInfo, Gradebook } from '../types/types';
import { logger } from './Logger';

import './StudentScreen.css';
import { StudentWebSocketMessage } from '../types/websocket-messages';

let backendURL = process.env.REACT_APP_BACKEND_WS_URL
if (backendURL == null){
    backendURL = "ws://localhost:8000"
}

type Permissions = {
  [lesson: string]: string[];
};

const StudentScreen: React.FC = () => {
  logger.log('Creating StudentScreen');
  const [name, setName] = useState('');
  const [loading, setLoading] = useState(true);
  const codeDataRef = useRef<CodeData>(initialCodeData);
  const { course = '' } = useParams<{ course?: string }>();
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [lessonId, setLessonId] = useState('');
  const [permissions, setPermissions] = useState<Permissions>({});
  const [unpermissions, setUnpermissions] = useState<Permissions>({});
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const { changeColorScheme } = useColorScheme();
  const [copyEnabled, setCopyEnabled] = useState(true);
  const [gradebook, setGradebook] = useState<Gradebook>({});
  const [lessonsInfo, setLessonsInfo] = useState<LessonInfo[]>([]);
  const [infoMessage, setInfoMessage] = useState('');

  // websocket variables
  const [isInitialized, setIsInitialized] = useState(false);
  const [connectedMessage, setConnectedMessage] = useState('(disconnected)');
  const maxReconnectAttempts = 13;
  const reconnectIntervalMaxSecs = 30;
  const pingIntMillis = 60000; // 1 minute  ##### SET BACK TO 60000, just put up as annoying for testing
  const pongIntMillis = 60000; // 1 minute
  const pongCheckIntervalMillis = 65000; // 1 minute 5 seconds
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const lastPongRef = useRef<number>(Date.now());
  const pongTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  // We have moved from raw WebSockets to react-use-websocket
  // hopefully this removes the convolluted connection and reconnection logic, handled by the library
  // unfortunately, we still have to put in the ping/pong heartbeat logic
  // we now dont have the stale closure problem and can do partial updates using states, much better
  // removed destructuring of ws messages, cant do this with a switch due to redeclaration error
  // this means we at least avoid having ..State states, we can also have a clear structure using a websocket-message type

  // Initialize name and lesson before WebSocket connection
  useEffect(() => {
    const initializeStudent = () => {
      const storedName = localStorage.getItem('name');
      const storedLessonId = localStorage.getItem('currentLessonId');
      
      if (storedLessonId && storedLessonId !== lessonId) {
        setLessonId(storedLessonId);
      }
      
      let studentName: string | null = storedName;
      let initialPrompt = true;
      
      while (!studentName || studentName.trim() === "" || studentName.includes('-')) {
        if (initialPrompt) {
          studentName = prompt("Please enter your name:");
          initialPrompt = false;
        } else {
          studentName = prompt('Please enter your name (cannot be empty or contain "-"):');
        }
        if (studentName && studentName.trim() !== "" && !studentName.includes('-')) {
          localStorage.setItem('name', studentName);
        }
      }
      
      setName(studentName ?? "Error");
      setIsInitialized(true);
    };

    initializeStudent();
  }, []); // Run once on mount

  // Replace raw WebSocket with useWebSocket hook
  const {
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(
    isInitialized ? 
      `${backendURL}/ws/student?name=${encodeURIComponent(name)}&courseName=${encodeURIComponent(course)}&lessonId=${encodeURIComponent(lessonId)}` :
      null, 
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: maxReconnectAttempts,
      reconnectInterval: (attemptNumber) => Math.min(Math.pow(2, attemptNumber) * 1000, reconnectIntervalMaxSecs * 1000),
      onOpen: () => {
        logger.log('StudentScreen: WS connected');
        setConnectedMessage('');
        setInfoMessage('');
        sendMessage(JSON.stringify({ type: 'initialConnection' }));
        startHeartbeat();
      },
      onClose: () => {
        logger.log('StudentScreen: WS disconnected');
        setConnectedMessage('(disconnected)');
        clearHeartbeat();
      },
      onError: (event) => {
        logger.error('StudentScreen: WS error:', event);
        setInfoMessage('WS error');
      },
    }
  );

   // Add this useEffect to handle connection status changes
  useEffect(() => {
    const newStatus = {
      [ReadyState.CONNECTING]: '(connecting)',
      [ReadyState.OPEN]: '',
      [ReadyState.CLOSING]: '(closing)',
      [ReadyState.CLOSED]: '(disconnected)',
      [ReadyState.UNINSTANTIATED]: '(uninstantiated)',
    }[readyState];

    setConnectedMessage(newStatus);

    // Optionally set info message for specific states
    if (readyState === ReadyState.CLOSED) {
      setInfoMessage('Connection lost. Attempting to reconnect...');
    } else if (readyState === ReadyState.OPEN) {
      setInfoMessage('');
    }
  }, [readyState]);


  const startHeartbeat = useCallback(() => {
    // Clear any existing intervals
    clearHeartbeat();
    
    // Start ping interval
    pingIntervalRef.current = setInterval(() => {
      if (readyState === ReadyState.OPEN) {
        logger.log('>>>>>> StudentScreen: sending ping');
        sendMessage(JSON.stringify({ type: 'ping' }));
        
        // Set timeout for pong response
        pongTimeoutRef.current = setTimeout(() => {
          const timeSinceLastPong = Date.now() - lastPongRef.current;
          if (timeSinceLastPong > pongCheckIntervalMillis) {
            logger.log('Pong timeout detected, connection may be stale');
            const ws = getWebSocket();
            if (ws) {
              ws.close(); // This will trigger the reconnection process
            }
          }
        }, pongIntMillis);
      }
    }, pingIntMillis);
  }, [readyState, sendMessage, getWebSocket]);

  const clearHeartbeat = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
    if (pongTimeoutRef.current) {
      clearTimeout(pongTimeoutRef.current);
      pongTimeoutRef.current = null;
    }
  }, []);

  // Handle pong messages in your message handler
  useEffect(() => {
    if (lastMessage) {
      const message = JSON.parse(lastMessage.data);
      if (message.type === 'pong') {
        logger.log('<<<<<< StudentScreen: Received pong');
        lastPongRef.current = Date.now();
        // Clear existing pong timeout since we got a response
        if (pongTimeoutRef.current) {
          clearTimeout(pongTimeoutRef.current);
          pongTimeoutRef.current = null;
        }
      }
      // ... handle other message types
    }
  }, [lastMessage]);

  // Start heartbeat when connection is established
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      startHeartbeat();
    }
    return () => clearHeartbeat();
  }, [readyState, startHeartbeat, clearHeartbeat]);


  // Handle incoming messages
  useEffect(() => {
    if (lastMessage) {
      try {
        const message = JSON.parse(lastMessage.data) as StudentWebSocketMessage;
        
        switch (message.type) {
          case 'initialUpdate':
            logger.log('<<<<<< StudentScreen: Received initialUpdate:', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: { 
                ...codeDataRef.current.studentFiles,
                [name]: message.studentFiles 
              },
              teacherFiles: message.teacherFiles,
              userInfo: {
                ...message.userInfo,
                [name]: {
                  userType: 'student',
                  caretPosition: { line: 1, col: 1 },
                  highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
                  activeFile: null,
                }
              },
              changeType: 'initialUpdate',
              studentConnections: message.studentConnections,
              teacherConnections: message.teacherConnections,
              allStudents: message.allStudents,
              version: codeDataRef.current.version + 1
            };

            if (message.lessonName !== lessonName || message.lessonId !== lessonId) {
              localStorage.setItem('currentLessonId', message.lessonId);
              setLessonId(message.lessonId);
              setLessonName(message.lessonName);
            }
            if (message.courseName !== courseName || message.courseId !== courseId) {
              setCourseName(message.courseName);
              setCourseId(message.courseId);
            }

            setPermissions(message.permissionsStudent);
            setUnpermissions(message.unpermissionsStudent);
            setCopyEnabled(message.copyEnabled);
            setGradebook(message.gradebook);
            setLessonsInfo(message.lessonsInfo);
            setLoading(false);
            forceUpdate();
            break;

          case 'teacherTextUpdate':
            logger.log('<<<<<< StudentScreen: Received teacherTextUpdate message: ', message);
            const existingFile = codeDataRef.current.teacherFiles?.[message.fileName];
            // this probably should come from a TeacherFile type
            const updatedTeacherFile: {
              text: string;
              hidden: boolean;
              classOnly: boolean;
            } = {
              text: message.fileText,
              hidden: existingFile?.hidden ?? false,    // Use existing or default
              classOnly: existingFile?.classOnly ?? false,  // Use existing or default
            };
            codeDataRef.current = {
              ...codeDataRef.current,
              teacherFiles: {
                ...codeDataRef.current.teacherFiles,
                [message.fileName]: updatedTeacherFile
              },
              userInfo: {
                ...codeDataRef.current.userInfo,
                [message.sentFrom]: message.isStealthMode ? defaultStealthModeUserInfo : message.userInfoName,
              },
              changeType: message.isStealthMode ? null : (message.sentFromTeacher ? 'liveCoding' : 'studentUpdatesTeacher'),
              changeFile: message.isStealthMode ? null : message.fileName,
              changeName: message.isStealthMode ? null : message.sentFrom,
              teacherTextEditFile: message.teacherTextEditFile,
              teacherTextEditName: message.teacherTextEditName,
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
  
          case 'studentTextUpdate':
            // this is a teacher updating this students text
            logger.log('<<<<<< StudentScreen: Received a studentTextUpdate message: ', message);

            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: {
                ...codeDataRef.current.studentFiles,
                [message.studentName]: {
                  ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[message.studentName] : {}),
                  [message.studentFileName]: {
                    ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[message.studentName][message.studentFileName] : {}),
                    text: message.studentFileText
                  }
                }
              },
              userInfo: {
                ...codeDataRef.current.userInfo,
                [message.sentFrom]: message.isStealthMode ? defaultStealthModeUserInfo : message.userInfoName,
              },
              changeType: message.isStealthMode ? null : 'teacherUpdatesStudent',  // this must be a teacher changing the student file so therefore teacherUpdateStudent
              changeFile: message.isStealthMode ? null : message.studentFileName,
              changeName: message.isStealthMode ? null : message.sentFrom,
              teacherTextEditFile: message.teacherTextEditFile,
              teacherTextEditName: message.teacherTextEditName,
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
  
          // studentStructureUpdate, add this if/when teachers can create/updateName/remove student files
    
          case 'teacherStructureUpdate':
            logger.log('<<<<<< StudentScreen: Received teacherStructureUpdate message: ', message);

            codeDataRef.current = {
              ...codeDataRef.current,
              teacherFiles: message.teacherFiles,
              userInfo: message.userInfo,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
    
          case 'studentStructureUpdate':
            // this is not ideal, if a student gets this update, which is an add file, 
            // the student might lose their recent changes that the teacher has not received yet
            logger.log('<<<<<< StudentScreen: Received studentStructureUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: {
                ...codeDataRef.current.studentFiles,
                [name]: message.studentFiles,
              },
              userInfo: message.userInfo,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
    
          case 'teacherCourseUpdate':
            logger.log('<<<<<< StudentScreen: Received teacherCourseUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            logger.log('SS WS teacherCourseUpdate setting lessonsInfo: ', message.lessonsInfo);
            setLessonsInfo(message.lessonsInfo);
            logger.log('SS WS teacherCourseUpdate setting copyEnabled: ', message.copyEnabled);
            setCopyEnabled(message.copyEnabled);
            forceUpdate();  // force a rerender
            break;
    
          case 'gradingUpdate':
            logger.log('<<<<<< StudentScreen: Received gradingUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            logger.log('SS WS gradingUpdate setting gradebook: ', message.gradebook);
            setGradebook(message.gradebook);
            logger.log('SS WS gradingUpdate setting lessonsInfo: ', message.lessonsInfo);
            setLessonsInfo(message.lessonsInfo);
            forceUpdate();  // force a rerender
            break;
    
          case 'connectionUpdate':
            logger.log('<<<<<< StudentScreen: Received connectionUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              studentConnections: message.studentConnections,
              teacherConnections: message.teacherConnections,
              allStudents: message.allStudents,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
    
          case 'permissionsUpdate':
            logger.log('<<<<<< StudentScreen: Received permissionsUpdate message: ', message);
            setPermissions(message.permissionsStudent);
            setUnpermissions(message.unpermissionsStudent);
            forceUpdate();  // force a rerender
            break;
            
          case 'pong':
            logger.log('<<<<<< StudentScreen: Received pong');
            lastPongRef.current = Date.now();
            break;
    
          default:
            logger.error('<<<<<< StudentScreen: Unknown message type received from server, message:', message);
        }
      } catch (err) {
        logger.error('Error processing message:', err);
      }
    }
  }, [lastMessage, name, courseName, courseId, lessonName, lessonId]);

 
  // Modified updateFile to use new sendMessage
  const debouncedUpdateFile = useCallback(
    debounce((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
      if (!loading && readyState === ReadyState.OPEN) {
        const info = codeDataRef.current.userInfo[relevantName];
        
        if (codeDataRef.current.changeType !== 'initialUpdate') {
          const message = { 
            type: isTeacherFile ? 'updateTeacherText' : 'updateText', 
            fileName: fileName, 
            text: text, 
            info: info 
          };
          logger.log('>>>>>> StudentScreen: updateFile: sending message:', message);
          sendMessage(JSON.stringify(message));
        }
      }
    }, 300),
    [loading, readyState, sendMessage]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateFile.cancel();
    };
  }, [debouncedUpdateFile]);

  const updateFile = useCallback((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
    debouncedUpdateFile(isTeacherFile, relevantName, fileName, text);
  }, [debouncedUpdateFile]);

  const switchLesson = useCallback((newLessonId: string) => {
    logger.log('>>>>>> StudentScreen: lessonId switched to ', newLessonId);
    sendMessage(JSON.stringify({ type: 'switchLesson', lessonId: newLessonId }));
    setLessonId(newLessonId);
    localStorage.setItem('currentLessonId', newLessonId);
  }, []);

  const submitLesson = useCallback((gradebook: Gradebook) => {
    // unfortunately have to update the whole state, cannot do a partial, due to stale state prev in closure
    logger.log('SL: gradebook: ', gradebook, ', name: ', name, ', lessonIdState: ', lessonId);
    const submitted = gradebook[name][lessonId].submitted;
    logger.log('>>>>>> StudentScreen: submit lesson, submitted: ', submitted);
    sendMessage(JSON.stringify({ type: 'submitLesson', submitted: submitted }));
    setGradebook(gradebook);
  }, [name, lessonId]);

  // naming conventions, local function are called createLesson and callback props are onCreateLesson
  // trying to stick to CRUD, Create, (Read?), Update, Delete, for changing the lesson I have used switch

  const updateGradeMax = useCallback((lessonIdParam: string, lessonsInfo: LessonInfo[]) => {
    logger.error('SS: this should not happen, cant update grade max from student screen');
  }, []);

  const updateGradeAndFeedback = useCallback((studentName: string, lessonIdParam: string, gradebook: Gradebook) => {
    logger.error('SS: this should not happen, cant update grade and feedback from student screen');
  }, []);

  const createLesson = useCallback(() => {
    logger.error('SS: this should not happen, cant add lesson from student screen');
  }, []);

  const updateLessonName = useCallback((lessonNameOld: string, lessonNameNew: string) => {
    logger.error('SS: this should not happen, cant edit lesson name from student screen');
  }, []);

  const updateLessonState = useCallback((lessonInfo: LessonInfo) => {
    logger.error('SS: this should not happen, cant change lesson state from student screen');
  }, []);

  const deleteLesson = useCallback((lessonId: string) => {
    logger.error('SS: this should not happen, cant delete lesson from student screen');
  }, []);


  const createFile = useCallback((isTeacherFile: boolean, relevantName: string, fileName: string, text: string) => {
    logger.log('>>>>>> SS: createFile, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', fileName:', fileName, 'text:', text);
    sendMessage(JSON.stringify({ type: 'createFile', fileName: fileName, text: text }));
  }, []);

  const updateFileName = useCallback((isTeacherFile: boolean, relevantName: string, oldFileName: string, newFileName: string) => {
    logger.log('>>>>>> SS: updateFileName, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', oldFileName:', oldFileName, 'newFileName:', newFileName);
    sendMessage(JSON.stringify({ type: 'updateFileName', oldFileName: oldFileName, newFileName: newFileName }));
  }, []);

  const updateFileHidden = useCallback((fileName: string, hidden: boolean) => {
    logger.error('SS: this should not happen, cant change file hidden from student screen');
  }, []);

  const updateFilePermission = useCallback((fileName: string) => {
    logger.error('SS: updateFilePermission we should not get here, student cant change permissions');
  }, []);

  const updateFileClassOnly = useCallback((fileName: string, isClassOnly: boolean) => {
    logger.error('SS: updateFileClassOnly we should not get here, student cant change classOnly on teacher files');
  }, []);

  const deleteFile = useCallback((isTeacherFile: boolean, relevantName: string, fileName: string) => {
    logger.log('>>>>>> SS: onDeleteFile, isTeacherFile: ', isTeacherFile, ', relevantName: ', relevantName, ', fileName:', fileName);
    sendMessage(JSON.stringify({ type: 'deleteFile', fileName: fileName }));
  }, []);

  const logOut = () => {
    logger.log('logOut function called');
    const isConfirmed = window.confirm("Are you sure you want to log out?");
  
    if (isConfirmed) {
      if (readyState === ReadyState.OPEN) {
        logger.log('>>>>>> StudentScreen sending logOut message');
        sendMessage(JSON.stringify({
          type: 'logOut',
        }));
        
        // Explicitly close the connection if needed
        const ws = getWebSocket();
        if (ws) {
          ws.close();
        }
      }
  
      // Clear any remaining intervals
      clearHeartbeat();
  
      // Clean up local storage
      localStorage.removeItem('name');
      localStorage.removeItem('colorScheme');
      localStorage.removeItem('currentLessonId');
      changeColorScheme('dark');
  
      // Navigate away
      navigate('/');
    }
  };

  const handleMenuItemClick = useCallback((menuItem: string) => {
    logger.log('SS: handleMenuItemClick: ', menuItem);
    if (menuItem === 'Log out') {
      logOut();

    } else if (menuItem === 'Home') {
      logger.log('StudentScreen: Calling home function');
      navigate(`/`);

    } else if (menuItem === 'Courses') {
      logger.log('StudentScreen: Calling courses function');
      navigate(`/Student`);
    }
  }, []);

  const setStealthMode = useCallback(() => {
    logger.error('SS: setStealthMode we should not get here, student cant set stealth mode');
  }, []);

  const updateCopyEnabled = useCallback(() => {
    logger.error('SS: updateCopyEnabled we should not get here, student cant set copy enabled');
  }, []);

  
  if (loading || courseName === '' || lessonName === '') {
    return <div>Loading...</div>;
  }

  return (
    <div className="editor-container">
      <EditorHeader
        infoMessage={infoMessage}
        connectedMessage={connectedMessage}
        name={name}
        isTeacher={false}
        canClearRedis={false}
        isStealthMode={false}
        showStealthToggle={false}
        handleMenuItemClick={handleMenuItemClick}
        setStealthMode={setStealthMode}
      />
      <div className='col-12 code-editor-parent'>
        {name && <CodeEditor
          codeDataRef={codeDataRef}
          isTeacher={false}
          name={name}
          course={courseName}
          lesson={lessonName}
          courseId={courseId}
          lessonId={lessonId}
          permissionedFiles={permissions[lessonId] || []}
          allPermissions={{}}
          unpermissionedFiles={unpermissions[lessonId] || []}
          copyEnabled={copyEnabled}

          gradebook={gradebook}
          lessonsInfo={lessonsInfo}

          onUpdateCopyEnabled={updateCopyEnabled}

          onUpdateGradeMax={updateGradeMax}
          onUpdateGradeAndFeedback={updateGradeAndFeedback}

          onSwitchLesson={switchLesson}
          onSubmitLesson={submitLesson}
          onCreateLesson={createLesson}
          onUpdateLessonName={updateLessonName}
          onUpdateLessonState={updateLessonState}
          onDeleteLesson={deleteLesson}

          onCreateFile={createFile}
          onUpdateFileName={updateFileName}
          onUpdateFile={updateFile}
          onUpdateFileHidden={updateFileHidden}
          onUpdateFilePermission={updateFilePermission}
          onUpdateFileClassOnly={updateFileClassOnly}
          onDeleteFile={deleteFile}
        />}
      </div>
    </div>
  );
};

export default StudentScreen;