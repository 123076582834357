import React, { useState, useEffect, useRef, useReducer, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import useWebSocket, { ReadyState } from 'react-use-websocket';
import debounce from 'lodash/debounce';

import EditorHeader from './EditorHeader';
import CodeEditor from './CodeEditor';
import { useColorScheme } from './ColorSchemeContext';   
import { CodeData, initialCodeData, defaultStealthModeUserInfo, UserInfo, LessonInfo, Gradebook, AllPermissions } from '../types/types';
import { logger } from './Logger';

import './StudentScreen.css';
import { TeacherWebSocketMessage } from '../types/websocket-messages';


let backendURL = process.env.REACT_APP_BACKEND_WS_URL
if (backendURL == null){
    backendURL = "ws://localhost:8000"
}

const TeacherScreen: React.FC = () => {
  logger.log('Creating TeacherScreen');
  // Need to add in courseId and lessonId to states
  const codeDataRef = useRef<CodeData>(initialCodeData);
  const [teacherName, setTeacherName] = useState('');
  const { course = '' } = useParams<{ course?: string }>();
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState('');
  const [lessonName, setLessonName] = useState('');
  const [lessonId, setLessonId] = useState('');
  const [loading, setLoading] = useState(true);
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [allPermissions, setAllPermissions] = useState<AllPermissions>({});
  const [isStealthMode, setIsStealthMode] = useState(false);
  const [copyEnabled, setCopyEnabled] = useState(true);
  const { changeColorScheme } = useColorScheme();
  const [gradebook, setGradebook] = useState<Gradebook>({});
  const [lessonsInfo, setLessonsInfo] = useState<LessonInfo[]>([]);
  const [infoMessage, setInfoMessage] = useState('');

  // websocket variables
  const [isInitialized, setIsInitialized] = useState(false);
  const [connectedMessage, setConnectedMessage] = useState('(disconnected)');
  const maxReconnectAttempts = 13;
  const reconnectIntervalMaxSecs = 30;
  const pingIntMillis = 60000; // 1 minute
  const pongIntMillis = 60000; // 1 minute
  const pongCheckIntervalMillis = 65000; // 1 minute 5 seconds
  const pingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const lastPongRef = useRef<number>(Date.now());
  const pongTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();

  // We have moved from raw WebSockets to react-use-websocket
  // hopefully this removes the convolluted connection and reconnection logic, handled by the library
  // unfortunately, we still have to put in the ping/pong heartbeat logic
  // we now dont have the stale closure problem and can do partial updates using states, much better
  // removed destructuring of ws messages, cant do this with a switch due to redeclaration error
  // this means we at least avoid having ..State states, we can also have a clear structure using a websocket-message type
  
  // Initialize teacher name and lesson before WebSocket connection
  useEffect(() => {
    const initializeTeacher = () => {
      const storedName = localStorage.getItem('teacherName');
      const storedLessonId = localStorage.getItem('currentLessonId');
      
      if (storedLessonId && storedLessonId !== lessonId) {
        setLessonId(storedLessonId);
      }
      
      let name: string | null = storedName;
      let initialPrompt = true;
      
      while (!name || name.trim() === "" || name.includes('-')) {
        if (initialPrompt) {
          name = prompt("Please enter your name:");
          initialPrompt = false;
        } else {
          name = prompt('Please enter your name (cannot be empty or contain "-"):');
        }
        if (name && name.trim() !== "" && !name.includes('-')) {
          localStorage.setItem('teacherName', name);
        }
      }
      
      setTeacherName(name ?? "Error");
      
      // Set initial userInfo for teacher
      codeDataRef.current = {
        ...codeDataRef.current,
        userInfo: {
          ...codeDataRef.current.userInfo,
          [name ?? "Error"]: {
            userType: 'teacher',
            caretPosition: { line: 1, col: 1 },
            highlightedRange: { anchor: { line: 1, col: 1 }, head: { line: 1, col: 1 } },
            activeFile: null,
          }
        },
        version: codeDataRef.current.version + 1
      };
      
      setIsInitialized(true);
    };

    initializeTeacher();
  }, []);

 

  // WebSocket setup
  const {
    sendMessage,
    lastMessage,
    readyState,
    getWebSocket,
  } = useWebSocket(
    isInitialized ? 
      `${backendURL}/ws/teacher?name=${encodeURIComponent(teacherName)}&courseName=${encodeURIComponent(course)}&lessonId=${encodeURIComponent(lessonId)}` 
      : null,
    {
      shouldReconnect: (closeEvent) => true,
      reconnectAttempts: maxReconnectAttempts,
      reconnectInterval: (attemptNumber) => 
        Math.min(Math.pow(2, attemptNumber) * 1000, reconnectIntervalMaxSecs * 1000),
      onOpen: () => {
        logger.log('TeacherScreen: WS connected');
        setConnectedMessage('');
        setInfoMessage('');
        sendMessage(JSON.stringify({ type: 'initialConnection' }));
        startHeartbeat();
      },
      onClose: () => {
        logger.log('TeacherScreen: WS disconnected');
        setConnectedMessage('(disconnected)');
        clearHeartbeat();
      },
      onError: (event) => {
        logger.error('TeacherScreen: WS error:', event);
        setInfoMessage('WS error');
      },
    }
  );

   // Add connection status effect
   useEffect(() => {
    const newStatus = {
      [ReadyState.CONNECTING]: '(connecting)',
      [ReadyState.OPEN]: '',
      [ReadyState.CLOSING]: '(closing)',
      [ReadyState.CLOSED]: '(disconnected)',
      [ReadyState.UNINSTANTIATED]: '(uninstantiated)',
    }[readyState];

    setConnectedMessage(newStatus);

    if (readyState === ReadyState.CLOSED) {
      setInfoMessage('Connection lost. Attempting to reconnect...');
    } else if (readyState === ReadyState.OPEN) {
      setInfoMessage('');
    }
  }, [readyState]);

  // Heartbeat mechanism
  const startHeartbeat = useCallback(() => {
    clearHeartbeat();
    
    pingIntervalRef.current = setInterval(() => {
      if (readyState === ReadyState.OPEN) {
        logger.log('>>>>>> TeacherScreen: sending ping');
        sendMessage(JSON.stringify({ type: 'ping' }));
        
        pongTimeoutRef.current = setTimeout(() => {
          const timeSinceLastPong = Date.now() - lastPongRef.current;
          if (timeSinceLastPong > pongCheckIntervalMillis) {
            logger.log('Pong timeout detected, connection may be stale');
            const ws = getWebSocket();
            if (ws) {
              ws.close();
            }
          }
        }, pongIntMillis);
      }
    }, pingIntMillis);
  }, [readyState, sendMessage, getWebSocket]);

  const clearHeartbeat = useCallback(() => {
    if (pingIntervalRef.current) {
      clearInterval(pingIntervalRef.current);
      pingIntervalRef.current = null;
    }
    if (pongTimeoutRef.current) {
      clearTimeout(pongTimeoutRef.current);
      pongTimeoutRef.current = null;
    }
  }, []);

  // Start heartbeat when connection is established
  useEffect(() => {
    if (readyState === ReadyState.OPEN) {
      startHeartbeat();
    }
    return () => clearHeartbeat();
  }, [readyState, startHeartbeat, clearHeartbeat]);

  // Handle incoming messages
  useEffect(() => {
    if (lastMessage) {
      try {
        const message = JSON.parse(lastMessage.data) as TeacherWebSocketMessage;
        
        switch (message.type) {
          case 'initialUpdate':
            logger.log('<<<<<< TeacherScreen: Received initialUpdate:', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              teacherFiles: message.teacherFiles,
              studentFiles: message.studentFiles,
              userInfo: message.userInfo,
              changeType: 'initialUpdate',
              lastEditTimes: message.lastEditTimes,
              currentTimestampBE: message.currentTimestamp,
              currentTimestampFE: Date.now(),
              studentConnections: message.studentConnections,
              teacherConnections: message.teacherConnections,
              allStudents: message.allStudents,
              version: codeDataRef.current.version + 1
            };

            if (message.lessonName !== lessonName || message.lessonId !== lessonId) {
              localStorage.setItem('currentLessonId', message.lessonId);
              setLessonId(message.lessonId);
              setLessonName(message.lessonName);
            }
            if (message.courseName !== courseName || message.courseId !== courseId) {
              setCourseName(message.courseName);
              setCourseId(message.courseId);
            }

            setIsStealthMode(message.isStealthMode);
            setAllPermissions(message.permissionsTeacher);
            setCopyEnabled(message.copyEnabled);
            setGradebook(message.gradebook);
            setLessonsInfo(message.lessonsInfo);
            setLoading(false);
            forceUpdate();
            break;

          case 'studentTextUpdate':
            logger.log('<<<<<< TeacherScreeen: Received studentTextUpdate via WS, message: ', message);
            
            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: {
                ...codeDataRef.current.studentFiles,
                [message.studentName]: {
                  ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[message.studentName] : {}),
                  [message.studentFileName]: {
                    ...(codeDataRef.current.studentFiles ? codeDataRef.current.studentFiles[message.studentName][message.studentFileName] : {}),
                    text: message.studentFileText
                  }
                }
              },
              userInfo: {
                ...codeDataRef.current.userInfo,
                [message.sentFrom]: message.isStealthMode ? defaultStealthModeUserInfo : message.userInfoName,
              },
              lastEditTimes: message.lastEditTimes,
              currentTimestampBE: message.currentTimestamp,
              currentTimestampFE: Date.now(),
              changeType: message.isStealthMode ? null : (message.sentFromTeacher ? 'teacherUpdatesStudent' : 'studentUpdatesSelf'),
              changeFile: message.isStealthMode ? null : message.studentFileName,
              changeName: message.isStealthMode ? null : message.sentFrom,
              version: codeDataRef.current.version + 1 
            };
            forceUpdate();  // force a rerender
            break;

          case 'teacherTextUpdate':
            logger.log('<<<<<< TeacherScreeen: Received teacher text update from teacher via WS, message: ', message);
            const existingFile = codeDataRef.current.teacherFiles?.[message.fileName];
            // this probably should come from a TeacherFile type
            const updatedTeacherFile: {
              text: string;
              hidden: boolean;
              classOnly: boolean;
            } = {
              text: message.fileText,
              hidden: existingFile?.hidden ?? false,    // Use existing or default
              classOnly: existingFile?.classOnly ?? false,  // Use existing or default
            };

            codeDataRef.current = {
              ...codeDataRef.current,
              teacherFiles: {
                ...codeDataRef.current.teacherFiles,
                [message.fileName]: updatedTeacherFile
              },
              userInfo: {
                ...codeDataRef.current.userInfo,
                [message.sentFrom]: message.isStealthMode ? defaultStealthModeUserInfo : message.userInfoName,
              },
              lastEditTimes: message.lastEditTimes,
              currentTimestampBE: message.currentTimestamp,
              currentTimestampFE: Date.now(),
              changeType: message.isStealthMode ? null : (message.sentFromTeacher ? 'liveCoding' : 'studentUpdatesTeacher'),
              changeFile: message.isStealthMode ? null : message.fileName,
              changeName: message.isStealthMode ? null : message.sentFrom,
              version: codeDataRef.current.version + 1 
            };
            forceUpdate();  // force a rerender
            break;

          case 'batchedStudentUpdate':
            logger.log('<<<<<< TeacherScreeen: Received batchedStudentUpdate via WS, message: ', message);

            let updatedStudentFiles = { ...codeDataRef.current.studentFiles };
            let updatedUserInfo = { ...codeDataRef.current.userInfo };

            // Update student files
            Object.entries(message.studentFiles).forEach(([studentName, files]) => {
              if (!updatedStudentFiles[studentName]) {
                updatedStudentFiles[studentName] = {};
              }
              Object.entries(files).forEach(([fileName, fileText]) => {
                updatedStudentFiles[studentName][fileName] = {
                  ...updatedStudentFiles[studentName][fileName],
                  text: fileText
                };
              });
            });

            // Update user info
            Object.entries(message.userInfo).forEach(([studentName, userInfo]) => {
              updatedUserInfo[studentName] = userInfo;
            });
            
            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: updatedStudentFiles,
              userInfo: updatedUserInfo,
              lastEditTimes: message.lastEditTimes,
              currentTimestampBE: message.currentTimestamp,
              currentTimestampFE: Date.now(),
              changeType: 'studentUpdatesSelf',
              changeFile: null,  // what to do about typing
              changeName: null, // what to do about typing
              version: codeDataRef.current.version + 1 
            };
            forceUpdate();  
            break;

          case 'studentStructureUpdate':
            logger.log('<<<<<< TeacherScreen: Received studentStructureUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              studentFiles: {
                ...codeDataRef.current.studentFiles,
                [message.studentName]: message.studentFiles
              },
              userInfo: message.userInfo,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
    
          case 'teacherStructureUpdate':
            logger.log('<<<<<< TeacherScreen: Received teacherStructureUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              teacherFiles: message.teacherFiles,
              userInfo: message.userInfo,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
    
          case 'teacherCourseUpdate':
            logger.log('<<<<<< TeacherScreen: Received teacherCourseUpdate message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              changeType: 'structureUpdate',
              version: codeDataRef.current.version + 1
            };
            setLessonsInfo(message.lessonsInfo);
            setCopyEnabled(message.copyEnabled);
            forceUpdate();  // force a rerender
            break;
    
          case 'permissionsUpdate':
            logger.log('<<<<<< TeacherScreen: Received permissionsUpdate message: ', message);
            setAllPermissions(message.permissionsTeacher);
            forceUpdate();  // force a rerender
            break;
    
          case 'gradingUpdate':
            logger.log('<<<<<< StudentScreen: Received gradingUpdate message: ', message);
            setGradebook(message.gradebook);
            setLessonsInfo(message.lessonsInfo);
            forceUpdate();  // force a rerender
            break;

          case 'connectionUpdate':
            logger.log('<<<<<< TeacherScreeen: Received connectionUpdate via WS, message: ', message);
            codeDataRef.current = {
              ...codeDataRef.current,
              studentConnections: message.studentConnections,
              teacherConnections: message.teacherConnections,
              allStudents: message.allStudents,
              version: codeDataRef.current.version + 1
            };
            forceUpdate();  // force a rerender
            break;
          
          case 'pong':
            logger.log('<<<<<< TeacherScreen: Received pong');
            lastPongRef.current = Date.now();
            break;

          default:
            logger.error('<<<<<< TeacherScreen: Unknown message type:', message);
        }
      } catch (err) {
        logger.error('Error processing message:', err);
      }
    }
  }, [lastMessage, teacherName, courseName, courseId, lessonName, lessonId]);



  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateFile = useCallback(
    debounce((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
      logger.log('TeacherScreen: updateFile: isTeacherFile: ', isTeacherFile);

      if (codeDataRef.current.changeType !== 'initialUpdate') {
        const message = {
          type: "updateText",
          isTeacherFile: isTeacherFile,
          teacherName: teacherName,
          name: name,
          fileName: fileName,
          text: text,
          userInfoTeacher: codeDataRef.current.userInfo[teacherName], // only send the teacher userInfo
        };
        if (!loading) {
          logger.log("TS: codeDataRef.current.userInfo[teacherName]", codeDataRef.current.userInfo[teacherName]);
          logger.log('>>>>>> update from teacher, sending message to WS, message:', message);
          sendMessage(JSON.stringify(message));
        }
      } else {
        logger.error('TS: getting initialUpdate from lower level, shouldnt be possible');
      }
    }, 300), // 300ms debounce time
    [loading, codeDataRef, teacherName]
  );

  useEffect(() => {
    return () => {
      debouncedUpdateFile.cancel();
    };
  }, [debouncedUpdateFile]);

  const updateFile = useCallback((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
    debouncedUpdateFile(isTeacherFile, name, fileName, text);
  }, [debouncedUpdateFile]);


  // Note: not all of these functions are not useCallbacks, check this, why not
  // should we also need to put the set state functions into dependencies??
  // what about the ws

  const updateGradeMax = useCallback((lessonIdParam: string, lessonsInfo: LessonInfo[]) => {
    const gradeMax = lessonsInfo.filter((lessonInfo) => lessonInfo.id === lessonIdParam)[0].gradeMax;
    logger.log('>>>>>> TeacherScreen: updateGradeMax');
    sendMessage(JSON.stringify({ type: 'updateGradeMax', lessonId: lessonIdParam, gradeMax: gradeMax }));
    setLessonsInfo(lessonsInfo);
  }, []);

  const updateGradeAndFeedback = useCallback((studentName: string, lessonIdParam: string, gradebook: Gradebook) => {
    const grade = gradebook[studentName][lessonIdParam].grade;
    const feedback = gradebook[studentName][lessonIdParam].feedback;
    logger.log('>>>>>> TeacherScreen: updateGradeAndFeedback');
    sendMessage(JSON.stringify({ type: 'updateGradeAndFeedback', studentName: studentName, lessonId: lessonIdParam, grade: grade, feedback: feedback }));
    setGradebook(gradebook);
  }, []);
  
  const switchLesson = useCallback((newLessonId: string) => {
    logger.log('>>>>>> TeacherScreen: lesson switched to ', newLessonId);
    sendMessage(JSON.stringify({ type: 'switchLesson', lessonId: newLessonId }));
    setLessonId(newLessonId);
    localStorage.setItem('currentLessonId', newLessonId);
  }, []);  

  const submitLesson = useCallback((gradebook: Gradebook) => {
    logger.error('TS: submitLesson, shouldnt get here, teacher cant submit a student lesson');
  }, []);  

  const createLesson = useCallback(() => {
    logger.log('>>>>>> TeacherScreen: createLesson');
    sendMessage(JSON.stringify({ type: 'createLesson' }));
  }, []);

  const updateLessonName = useCallback((lessonNameOld: string, lessonNameNew: string) => {
    logger.log('>>>>>> TeacherScreen: updateLessonName, lesson change from ', lessonNameOld, ' to ', lessonNameNew);
    sendMessage(JSON.stringify({ type: 'updateLessonName', lessonNameOld: lessonNameOld, lessonNameNew: lessonNameNew }));
  }, []);

  const updateLessonState = useCallback((lessonInfo: LessonInfo) => {
    logger.log('>>>>>> TeacherScreen: updateLessonState, lesson ', lessonInfo.name, ' state changed to ', lessonInfo.state);
    sendMessage(JSON.stringify({ type: 'updateLessonState', lessonId: lessonInfo.id, lessonState: lessonInfo.state }));
  }, []);

  const deleteLesson = useCallback((lessonId: string) => {
    logger.log('>>>>>> TeacherScreen: deleteLesson, lesson: ', lessonId);
    sendMessage(JSON.stringify({ type: 'deleteLesson', lessonId: lessonId }));
  }, []);

 
 
  const createFile = useCallback((isTeacherFile: boolean, name: string, fileName: string, text: string) => {
    logger.log('>>>>>> TS: createFile, isTeacherFile: ', isTeacherFile, ', name: ', name, ', fileName:', fileName, ', text:', text);
    sendMessage(JSON.stringify({ type: 'createFile', isTeacherFile: isTeacherFile, name: name, fileName: fileName, text: text }));
  }, []);

  const updateFileName = useCallback((isTeacherFile: boolean, name: string, oldFileName: string, newFileName: string) => {
    logger.log('>>>>>> TS: updateFileName, isTeacherFile: ', isTeacherFile, ', name: ', name, ', oldFileName:', oldFileName, 'newFileName:', newFileName);
    sendMessage(JSON.stringify({ type: 'updateFileName', isTeacherFile: isTeacherFile, name: name, oldFileName: oldFileName, newFileName: newFileName }));
  }, []);

  const updateFileHidden = useCallback((fileName: string, hidden: boolean) => {
    logger.log('>>>>>> TS: updateFileHidden, fileName: ', fileName, ', hidden changed to ', hidden);
    sendMessage(JSON.stringify({ type: 'updateFileHidden', fileName: fileName, hidden: hidden }));
  }, []);


  const updateFilePermission = useCallback((fileName: string, permission: boolean, studentName?: string) => {
    logger.log('TS: updateFilePermission, fileName: ', fileName, 'studentName:', studentName);

    // Determine the action and target for the confirmation message
    const action = permission ? 'allow' : 'disallow';
    const target = studentName ? `student ${studentName}` : 'all students';

    const isConfirmed = window.confirm(`Are you sure you want to ${action} editing for ${target} on file ${fileName}?`);

    if (isConfirmed) {
    
      let message;

      if (studentName) {
        // If studentName is provided and not '%%all%%', use 'updateFilePermissionStudent'
        message = {
          type: 'updateFilePermissionStudent',
          fileName: fileName,
          permission: permission,
          studentName: studentName
        };
      } else {
        // If studentName is not provided, use 'updateFilePermission' for all students
        message = {
          type: 'updateFilePermission',
          fileName: fileName,
          permission: permission
        };
      }
      logger.log('>>>>>> TS sending updateFilePermission/Student message: ', message);
      sendMessage(JSON.stringify(message));
    }
  }, []);

  const updateFileClassOnly = useCallback((fileName: string, isClassOnly: boolean) => {
    logger.log('TS: updateFileClassOnly called for fileName: ', fileName, ', isClassOnly: ', isClassOnly);

    const message = isClassOnly ? `Are you sure you want to make ${fileName} class only?` : `Are you sure you want to make ${fileName} part of the student starter files?`;

    const isConfirmed = window.confirm(message);

    if (isConfirmed) {
    
      const message = {
        type: 'updateFileClassOnly',
        fileName: fileName,
        isClassOnly: isClassOnly
      };
      logger.log('>>>>>> TeacherScreen sending updateFileClassOnly message: ', message);
      sendMessage(JSON.stringify(message));
     
    }
  }, []);

  const deleteFile = (isTeacherFile: boolean, name: string, fileName: string) => {
    logger.log('>>>>>> TeacherScreen: deleteFile, isTeacherFile: ', isTeacherFile, ', name: ', name, ', fileName:', fileName);
    sendMessage(JSON.stringify({ type: 'deleteFile', isTeacherFile: isTeacherFile, name: name, fileName: fileName }));
  };

  

  const clearRedis = () => {
    logger.log('clearRedis function called');
    const isConfirmed = window.confirm("Are you sure you want to clear the Redis cache? This action cannot be undone.");

    if (isConfirmed) {
      logger.log('>>>>>> TeacherScreen sending clearRedis message');
      sendMessage(JSON.stringify({
        type: 'clearRedis',
      }));
    }
  };

  const logOut = () => {
    logger.log('logOut function called');
    const isConfirmed = window.confirm("Are you sure you want to log out?");
  
    if (isConfirmed) {
      if (readyState === ReadyState.OPEN) {
        logger.log('>>>>>> TeacherScreen sending logOut message');
        sendMessage(JSON.stringify({
          type: 'logOut',
        }));
        
        const ws = getWebSocket();
        if (ws) {
          ws.close();
        }
      }
  
      clearHeartbeat();
      localStorage.removeItem('teacherName');
      localStorage.removeItem('colorScheme');
      localStorage.removeItem('currentLessonId');
      changeColorScheme('dark');
      navigate('/');
    }
  };

  const handleMenuItemClick = useCallback((menuItem: string) => {
    logger.log('TeacherScreen: handleMenuItemClick: ', menuItem);
    if (menuItem === 'Clear Redis') {
      logger.log('TeacherScreen: Calling clearRedis function');
      clearRedis();

    } else if (menuItem === 'Log out') {
      logger.log('TeacherScreen: Calling logOut function');
      logOut();

    } else if (menuItem === 'Home') {
      logger.log('TeacherScreen: Calling home function');
      navigate(`/`);

    } else if (menuItem === 'Courses') {
      logger.log('TeacherScreen: Calling courses function');
      navigate(`/Teacher406816`);
    }
  }, []);

  const setStealthMode = useCallback((isStealthModeParam: boolean) => {
    logger.log('TeacherScreen: setStealthMode, newIsStealthMode: ', isStealthModeParam);
    logger.log('>>>>>> TeacherScreen sending changeStealthMode message');
    sendMessage(JSON.stringify({
      type: 'updateStealthMode',
      isStealthMode: isStealthModeParam
    }));
    setIsStealthMode(isStealthModeParam);
  }, []);  

  const updateCopyEnabled = (copyEnabledParam: boolean) => {
    logger.log('>>>>>> TeacherScreen: updateCopyEnabled, copyEnabled: ', copyEnabledParam);
    sendMessage(JSON.stringify({ type: 'updateCopyEnabled', copyEnabled: copyEnabledParam }));
    setCopyEnabled(copyEnabledParam);  // optimistic updating
  };
  

 
  if (loading || courseName === '' || lessonName === '') {
    return <div>Loading...</div>;
  } 

  return (
    <div className="editor-container">
      <EditorHeader
        infoMessage={infoMessage}
        connectedMessage={connectedMessage}
        name={teacherName}
        isTeacher={true}
        canClearRedis={true}
        isStealthMode={isStealthMode}
        showStealthToggle={true}
        handleMenuItemClick={handleMenuItemClick}
        setStealthMode={setStealthMode}
      />
      <div className='col-12 code-editor-parent'>
        {teacherName && <CodeEditor 
          codeDataRef={codeDataRef}
          isTeacher={true}
          name={teacherName}
          course={courseName}
          lesson={lessonName}
          courseId={courseId}
          lessonId={lessonId}
          permissionedFiles={[]}
          allPermissions={allPermissions[lessonId] || {}}
          unpermissionedFiles={[]}
          copyEnabled={copyEnabled}

          gradebook={gradebook}
          lessonsInfo={lessonsInfo}

          onUpdateCopyEnabled={updateCopyEnabled}

          onUpdateGradeMax={updateGradeMax}
          onUpdateGradeAndFeedback={updateGradeAndFeedback}

          onSwitchLesson={switchLesson}
          onSubmitLesson={submitLesson}
          onCreateLesson={createLesson}
          onUpdateLessonName={updateLessonName}
          onUpdateLessonState={updateLessonState}
          onDeleteLesson={deleteLesson}
          
          onCreateFile={createFile}
          onUpdateFile={updateFile}
          onUpdateFileName={updateFileName}
          onUpdateFileHidden={updateFileHidden}
          onUpdateFilePermission={updateFilePermission}
          onUpdateFileClassOnly={updateFileClassOnly}
          onDeleteFile={deleteFile}
        />}
      </div>
    </div>
  );
};

export default TeacherScreen;
