import { useState, useRef, useEffect } from "react";
import { BsPencilFill, BsTrashFill } from "react-icons/bs";
import { FaEllipsisH, FaEllipsisV } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import { logger } from "../Logger";

import './FileDropdown.css';
import { FaChevronRight } from "react-icons/fa6";

interface FileDropdownProps {
  isHorizontalDots: boolean;
  fileName: string;
  allowModifyFileName: boolean;
  allowDeleteFile: boolean;
  isClassOnly: boolean;
  allowChangeClassOnly: boolean;
  onRenameClick: (fileName: string) => void;
  onDeleteClick: (fileName: string) => void;
  onChangeClassOnly: (fileName: string, isClassOnly: boolean) => void;

  // New optional props for permissions
  allStudents?: string[];
  permissionedStudents?: string[]; // List of students who have permission for this file
  onUpdateFilePermission?: (fileName: string, permission: boolean, studentName?: string) => void;
};

// This is used in the FilesPane as well as the main pane and output pane tab section
const FileDropdown: React.FC<FileDropdownProps> = ({ 
  isHorizontalDots, fileName, allowModifyFileName, allowDeleteFile, isClassOnly, allowChangeClassOnly, 
  onRenameClick, onDeleteClick, onChangeClassOnly,
  allStudents, permissionedStudents, onUpdateFilePermission
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [submenu, setSubmenu] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);


  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleRenameClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown renameClick, fileName: ', fileName);
    e.stopPropagation();
    onRenameClick(fileName);
    setIsOpen(false);
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown deleteClick');
    e.stopPropagation();
    onDeleteClick(fileName);
    setIsOpen(false);
  };

  const handleChangeClassOnlyClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    logger.log('FileDropdown changeClassOnlyClick');
    e.stopPropagation();
    onChangeClassOnly(fileName, !isClassOnly);
    setIsOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if ((dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) && 
        (toggleRef.current && !toggleRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    } else {
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setSubmenu(null);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && dropdownRef.current && toggleRef.current) {
      const toggleRect = toggleRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${toggleRect.bottom}px`;
      dropdownRef.current.style.left = `${toggleRect.right - dropdownRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  // Position the dropdown
  useEffect(() => {
    if (isOpen && dropdownRef.current && toggleRef.current) {
      const toggleRect = toggleRef.current.getBoundingClientRect();
      dropdownRef.current.style.top = `${toggleRect.bottom}px`;
      dropdownRef.current.style.left = `${toggleRect.right - dropdownRef.current.offsetWidth}px`;
    }
  }, [isOpen]);

  // Determine whether to show permission options
  const canEditPermissions = onUpdateFilePermission;

  // Determine current permission state for the file
  let permissionState: 'all' | 'none' | 'individual' = 'none';
  if (permissionedStudents && permissionedStudents.length > 0) {
    if (permissionedStudents.includes('%%all%%')) {
      permissionState = 'all';
    } else {
      permissionState = 'individual';
    }
  }

  // Handle menu actions
  const handlePermissionAll = () => {
    onUpdateFilePermission && onUpdateFilePermission(fileName, true);
    setIsOpen(false);
    setSubmenu(null);
  };

  const handlePermissionNone = () => {
    onUpdateFilePermission && onUpdateFilePermission(fileName, false);
    setIsOpen(false);
    setSubmenu(null);
  };

  const handleSubmenuToggle = (submenuName: string) => {
    setSubmenu((prevSubmenu) => (prevSubmenu === submenuName ? null : submenuName));
  };

  const handleStudentPermission = (studentName: string, permission: boolean) => {
    onUpdateFilePermission && onUpdateFilePermission(fileName, permission, studentName);
    setIsOpen(false);
    setSubmenu(null);
  };

  // Render submenu items
  const renderSubmenu = () => {
    if (!submenu || !allStudents) return null;

    let students: string[] = [];
    if (submenu === 'setPermission') {
      // List students who do not have permission
      const permissionedSet = new Set(permissionedStudents || []);
      students = allStudents.filter((student) => !permissionedSet.has(student));
    } else if (submenu === 'removePermission') {
      // List students who have individual permissions
      const permissionedSet = new Set(permissionedStudents || []);
      students = Array.from(permissionedSet).filter((name) => name !== '%%all%%');
    }

    return (
      <div className="file-menu-submenu">
        {students.map((studentName) => (
          <div
            key={studentName}
            className="file-menu-item"
            onClick={(e) => {
              e.stopPropagation();
              handleStudentPermission(studentName, submenu === 'setPermission');
            }}
          >
            {studentName}
          </div>
        ))}
      </div>
    );
  };

  // Main dropdown rendering
  return (
    <div className="file-menu">
      <div ref={toggleRef} onClick={handleToggle}>
        {isHorizontalDots ?
          <FaEllipsisH /> :
          <div className='tab-dropdown-button'>
            <FaEllipsisV />
          </div>
        }
      </div>
      {isOpen && (
        <div className="file-menu-dropdown" ref={dropdownRef}>
          {allowModifyFileName && (
            <div className="file-menu-item" onClick={handleRenameClick}>
              <BsPencilFill className="file-menu-icon" />
              Edit
            </div>
          )}
          {allowDeleteFile && (
            <div className="file-menu-item" onClick={handleDeleteClick}>
              <BsTrashFill className="file-menu-icon text-danger" />
              Delete
            </div>
          )}
          {allowChangeClassOnly && (
            <div className="file-menu-item" onClick={handleChangeClassOnlyClick}>
              <PiStudentFill className="file-menu-icon" />
              {isClassOnly ? 'Make C&S' : 'Make C'}
            </div>
          )}
          {canEditPermissions && (
            <>
              <div className="file-menu-divider"></div>
              {/* Permission options based on permissionState */}
              {permissionState === 'none' && (
                <>
                  <div className="file-menu-item" onClick={(e) => { e.stopPropagation(); handlePermissionAll(); }}>
                    Permission All
                  </div>
                  <div
                    className={`file-menu-item file-menu-item-with-submenu ${submenu === 'setPermission' ? 'active' : ''}`}
                    onClick={(e) => { e.stopPropagation(); handleSubmenuToggle('setPermission'); }}
                  >
                    + Permission <FaChevronRight className="margin-left-auto" />
                    {submenu === 'setPermission' && renderSubmenu()}
                  </div>
                </>
              )}
              {permissionState === 'individual' && (
                <>
                  <div className="file-menu-item" onClick={(e) => { e.stopPropagation(); handlePermissionAll(); }}>
                    Permission All
                  </div>
                  <div className="file-menu-item" onClick={(e) => { e.stopPropagation(); handlePermissionNone(); }}>
                    Permission None
                  </div>
                  <div
                    className={`file-menu-item file-menu-item-with-submenu ${submenu === 'setPermission' ? 'active' : ''}`}
                    onClick={(e) => { e.stopPropagation(); handleSubmenuToggle('setPermission'); }}
                  >
                    + Permission <FaChevronRight className="margin-left-auto" />
                    {submenu === 'setPermission' && renderSubmenu()}
                  </div>
                  <div
                    className={`file-menu-item file-menu-item-with-submenu ${submenu === 'removePermission' ? 'active' : ''}`}
                    onClick={(e) => { e.stopPropagation(); handleSubmenuToggle('removePermission'); }}
                  >
                    - Permission <FaChevronRight className="margin-left-auto" />
                    {submenu === 'removePermission' && renderSubmenu()}
                  </div>
                </>
              )}
              {permissionState === 'all' && (
                <div className="file-menu-item" onClick={(e) => { e.stopPropagation(); handlePermissionNone(); }}>
                  Permission None
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FileDropdown;