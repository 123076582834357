let backendURL = process.env.REACT_APP_BACKEND_URL
if (backendURL == null){
    backendURL = "http://localhost:8000"
}
export const sendTelegramError = async (error: string) => {
  try {
    const logErrorURL = backendURL + '/log-error';
    const response = await fetch(logErrorURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ error }),
    });
    
    if (!response.ok) {
      console.error('Failed to send error to Telegram');
    }
  } catch (e) {
      console.error('Failed to send error to Telegram:', e);
  }
};