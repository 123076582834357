// FileUserIndicator.tsx
import React from 'react';
import { CodeData } from '../types/types';
import { getInitials } from './utilities';
import './FileUserIndicator.css';

interface FileUserIndicatorProps {
  codeData: CodeData;
  fileName: string;
  studentName?: string;
}

const FileUserIndicator: React.FC<FileUserIndicatorProps> = ({ codeData, fileName, studentName }) => {
  const currentUsers = Object.entries(codeData.userInfo).filter(([userName, userInfo]) => {
    if (studentName) {
      return userInfo.activeFile === `student-${studentName}-${fileName}`;
    } else {
      return userInfo.activeFile === `teacher-name-${fileName}`;
    }
  });

  return (
    <div className="file-user-indicator">
      {currentUsers.map(([userName, userInfo]) => {
        const circleClassName = `file-initials-circle ${userInfo.userType}`;
        return <div key={userName} className={circleClassName}>{getInitials(userName)}</div>;
      })}
    </div>
  );
};

export default FileUserIndicator;